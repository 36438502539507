@import '~ng-zorro-antd/ng-zorro-antd.min.css';
@import 'variables';

html,
body {
  min-height: 100%;
  max-height: 100%;
  display: block;

  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;

  @media screen and (max-width: 767px) {
    overflow: hidden;
  }
}

.white-text {
  color: white;
}

.center-this {
  text-align: center;
}

.center-middle {
  display: flex;
  justify-content: center;
  margin-top: 50%;
}

.ant-layout-sider-zero-width-trigger {
  top: 0px;
}

////////////////////////////////
// NG zorro Mobile Overwrite
////////////////////////////////
.am-action-sheet-button-list-item,
.am-tabs-default-bar-tab,
.am-list-item {
  cursor: pointer;
}

.am-image-picker-list {
  padding: 9px 8px 9px;
}
.am-navbar {
  height: $search-bar-mobile-height;
}
.am-popover .am-popover-item-icon {
  margin-right: 1rem;
}
.am-popover-item {
  cursor: pointer;
}
.am-tabs {
  height: auto;
}
.am-drawer-content {
  overflow: hidden;
}
////////////////////////////////

@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
@import '~handsontable/dist/handsontable.full.css';
